<template>
  <div class="all-box">
    <topMenu :checkId="2"></topMenu>
    <!-- <screened class="search"></screened> -->
    <div class="found">
      <div class="top-box">
        <div class="found-left">
          <div class="left-bg"></div>
          <div class="left-title">编剧</div>
        </div>
        <div class="found-right">
          共有'<span style="color: #f9680d">{{ number }}</span
          >'位
        </div>
      </div>
      <div
        @click="toDetail(item.id)"
        v-for="item in personList"
        :key="item.id"
        class="list-box"
      >
        <img :src="item.image" :onerror="defaultImg" class="list-left" />
        <div class="list-right">
          <div class="name">{{ item.name }}</div>
          <div class="detail">
            <div class="detailLeft">职业</div>
            <div class="detailRight">{{ item.typeName }}</div>
          </div>
          <div class="detail">
            <div class="detailLeft">民族</div>
            <div class="detailRight">{{ item.nation }}</div>
          </div>
          <div class="detail">
            <div class="detailLeft">籍贯</div>
            <div class="detailRight">{{ item.job }}</div>
          </div>
          <div class="detail">
            <div class="detailLeft">毕业院校</div>
            <div class="detailRight">{{ item.school }}</div>
          </div>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>
<script>
import topMenu from "./topMenu.vue";
import screened from "./screened.vue";
import { getEntertainer, clickEntertainer } from "@/service/PeopleEntertainers";
export default {
  components: {
    topMenu,
    screened,
  },
  data() {
    return {
      defaultImg:
        'this.src="' + require("../../../assets/imgs/defaultDiagram.jpg") + '"',
      form: {
        typeId: 24,
        firstLetter: "",
        name: "",
      },
      pager: {
        pageNo: 1,
        pageSize: 9999,
        totalPage: 0,
      },
      number: 1,
      personList: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      const params = { ...this.form };
      const res = await getEntertainer({
        ...this.pager,
        condition: params,
      });
      this.personList = res.data.data;
      this.pager = {
        pageNo: res.data.pageNo,
        totalPage: res.data.totalPage,
        pageSize: res.data.pageSize,
      };
      this.number = res.data.totalCount;
      // 根据 firstLetter 字段按字母顺序排序
      // this.personList.sort((a, b) => {
      //   if (a.firstLetter < b.firstLetter) {
      //     return -1;
      //   }
      //   if (a.firstLetter > b.firstLetter) {
      //     return 1;
      //   }

      //   // 如果 firstLetter 相同，则根据姓氏排序
      //   const lastNameA = a.name.charAt(0);
      //   const lastNameB = b.name.charAt(0);

      //   if (lastNameA < lastNameB) {
      //     return -1;
      //   }
      //   if (lastNameA > lastNameB) {
      //     return 1;
      //   }

      //   return 0;
      // });
    },
    toDetail(id) {
      clickEntertainer(id);
      this.$router.push({
        path: "/PeopleEntertainers/detail",
        query: {
          id: id,
          page: "1",
        },
      });
    },
  },
};
</script>
<style scoped lang="less">
.all-box {
  position: relative;
  .search {
    position: absolute;
    top: 2.5467rem;
    right: 0.1333rem;
    width: 0.2667rem;
  }
  .found {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    margin-top: 0.1733rem;
    min-height: 12rem;
    .top-box {
      display: flex;
      justify-content: space-between;
      .found-left {
        display: flex;
        justify-content: space-between;
        .left-bg {
          background-image: url("../../../assets/imgs/introduction.png");
          width: 0.24rem;
          height: 0.4533rem;
          background-size: contain;
          background-repeat: no-repeat;
        }
        .left-title {
          font-size: 0.4533rem;
          font-weight: 800;
          margin-left: 0.2133rem;
        }
      }
      .found-right {
        display: flex;
        font-size: 0.32rem;
        color: #909399;
        line-height: 0.8rem;
      }
    }
    .list-box {
      margin-top: 0.2667rem;
      display: flex;
      .list-left {
        height: 3.7333rem;
        width: 2.8rem;
      }
      .list-right {
        height: 2.7867rem;
        margin-left: 0.4rem;
        .name {
          font-size: 0.4rem;
          font-weight: 800;
        }
        .detail {
          display: flex;
          margin-top: 0.25rem;
          .detailLeft {
            width: 1.3867rem;
            height: 0.5067rem;
            background: #bf805f;
            font-weight: 400;
            color: #ffffff;
            font-size: 0.2667rem;
            font-weight: 400;
            text-align: center;
            line-height: 0.5067rem;
          }
          .detailRight {
            height: 0.5067rem;
            line-height: 0.5067rem;
            font-size: 0.3467rem;
            font-weight: 500;
            color: #212122;
            margin-left: 0.1067rem;
          }
        }
      }
    }
  }
}
</style>
